import React from 'react';
import './BasicScreenVital.css';
import psycho from '../../../../Images/image-removebg-preview (46) 1.png';

const PsychologicalHealth = ({ visionData }) => {
    console.log(visionData);
    console.log('vvvvvvvvvvvvvvvvvvvvvvvv', visionData);

    return (
        <div>
            <h6 className="basicpsychohealthvital">Vision</h6>
            <div className="elementbasicscreenpsychohealth"></div>
            <div className="row">
                <div className="col-md-4">
                    {/* <img src={psycho} className='psycholabel' alt="Vision" /> */}
                </div>

                <div className="col-md-5">
                    {Array.isArray(visionData) ? (
                        <div>
                            {/* <h6 className="conditionpsycho1">Vision Condition</h6> */}
                            <div className="row">
                                {/* /////////////////// with glasses */}
                                <div className="col-md-8">
                                    <h6 className="conditionpsycho2">
                                        Vision With Glasses:
                                    </h6>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="conditionpsycho3">
                                        {visionData[0]?.vision_with_glasses === '2' ? 'Good' : (visionData[0]?.vision_with_glasses === '1' ? 'Poor' : 'NA')}
                                    </h5>
                                </div>

                                {/* ////////// without glasses */}
                                <div className="col-md-8">
                                    <h6 className="conditionpsycho2">
                                        Vision Without Glasses:
                                    </h6>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="conditionpsycho3">
                                        {visionData[0]?.vision_without_glasses === '1' ? 'Good' : (visionData[0]?.vision_without_glasses === '2' ? 'Poor' : 'NA')}
                                    </h5>
                                </div>

                                {/* ////////// color blindness */}
                                <div className="col-md-8">
                                    <h6 className="conditionpsycho2">
                                        Colour Blindness:
                                    </h6>
                                </div>
                                <div className="col-md-4">
                                    <h5 className="conditionpsycho3">
                                        {visionData[0]?.color_blindness === '1' ? 'Yes' : 'No'}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p>No vision data available</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PsychologicalHealth;

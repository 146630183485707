// import React from 'react';
// import './BasicScreenVital.css';
// import Auditory from '../../../../Images/ear (2).png';

// const AuditoryHealth = ({ auditoryData }) => {
//     console.log('AuditoryDataaaa', auditoryData);

//     // Check if "NAD" is present in any of the checkboxes
//     const isNADPresent = Array.isArray(auditoryData) && auditoryData.some(checkboxes => checkboxes.includes('NAD'));

//     return (
//         <div>
//             <h6 className="basicpsychohealthvital">Auditory</h6>
//             <div className="elementbasicscreenpsychohealth"></div>
//             <div className="row">
//                 <div className="col-md-4">
//                     <img src={Auditory} className='psycholabel' />
//                 </div>
//                 <div className="col-md-5">
//                     {Array.isArray(auditoryData) && auditoryData.length > 0 ? (
//                         <div>
//                             {/* Display only one condition for the entire set of checkboxes */}
//                             <h5 className="conditionpsycho">
//                                 {isNADPresent ? 'Good' : 'Bad'}
//                             </h5>
//                         </div>
//                     ) : (
//                         <p>No Auditory Data Found</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AuditoryHealth;

import React from 'react';
import './BasicScreenVital.css';
import Auditory from '../../../../Images/ear (2).png';

const AuditoryHealth = ({ auditoryData }) => {
    console.log('AuditoryDataaaa', auditoryData);

    // Check if "NAD" is present in any of the checkboxes
    const isNADPresent = Array.isArray(auditoryData) && auditoryData.some(checkboxes => Array.isArray(checkboxes) && checkboxes.includes('NAD'));

    return (
        <div>
            <h6 className="basicpsychohealthvital">Auditory</h6>
            <div className="elementbasicscreenpsychohealth"></div>
            <div className="row">
                <div className="col-md-4">
                    <img src={Auditory} className='psycholabel' alt="Auditory Icon" />
                </div>
                <div className="col-md-5">
                    {Array.isArray(auditoryData) && auditoryData.length > 0 ? (
                        <div>
                            {/* Display only one condition for the entire set of checkboxes */}
                            <h5 className="conditionpsycho">
                                {isNADPresent ? 'Good' : 'Bad'}
                            </h5>
                        </div>
                    ) : (
                        <p>No Auditory Data Found</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuditoryHealth;

